import React from "react";
import Layout from "../../../other/layout";
import LogoDesign from "../../../components/AC-StaticPages/products/LogoDesign";
import SEO from "../../../other/seo";

export default function logoDesign() {
  return (
    <Layout>
      <SEO title="Logo Design" />

      <LogoDesign />
    </Layout>
  );
}
