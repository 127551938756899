import React from "react";
import { Link } from "gatsby";
import LogoDeignServices_Hero from "../../../assets/img/LogoDeignServices_Hero.jpg";
import bearco from "../../../assets/img/BearcoOutdoors_Thumbnail.jpg";
import mediacare from "../../../assets/img/MediaCare_Thumbnail.jpg";
import procuts from "../../../assets/img/Procuts_Thumbnail.jpg";
import redshoe from "../../../assets/img/RedShoeSounds_Thumbnail.jpg";
import turbo from "../../../assets/img/TurboPainting_Thumbnail.jpg";
import uglide from "../../../assets/img/UglideNetworks_Thumbnail.jpg";

import styles from "../style/styles.module.css";

export default function LogoDesign() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Logo Design</h1>
          <h2>Give businesses a fresh look with a new logo.</h2>
          <p className={styles.productParag}>
            Affordable logo design services.
          </p>
          <img
            className={styles.hero}
            src={LogoDeignServices_Hero}
            alt="Brand logo on various products"
          />

          <p className={styles.productMargin}>
            Our designers will learn your customer’s business, so they can
            create a unique logo that accurately reflects their brand. Whether
            they’re looking to update their current logo or create a whole new
            one, we can help your customers stand out from the competition.
          </p>
          <p className={styles.productMargin}>
            To learn more, go to
            <a href="http://dfslogodesign.ca" target="_blank">
              dfslogodesign.ca (opens in new window)
            </a>
            or call us at
            <a href="tel:800-490-6327" target="_blank">
              800.490.6327
            </a>
            to get started.
          </p>
          <h3 className={styles.headingH3}>DFS Logo Design Reseller Program</h3>
          <ul>
            <li>3 package choices – unbeatable in terms of quality and cost</li>
            <li>Easy, 6-step process</li>
            <li>4 – 10 logo concepts (depending on package)</li>
            <li>Unlimited changes with most packages</li>
            <li>Final designs that are functional in all applications</li>
          </ul>
          <hr className={styles.productHr} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Logo Design For</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img src={bearco} alt="Bearco Outdoors logo"></img>
              <h3>Bearco Outdoors</h3>
            </div>
            <div className={styles.card}>
              <img src={mediacare} alt="MediaCare Strategies logo"></img>
              <h3>MediaCare Strategies</h3>
            </div>
            <div className={styles.card}>
              <img src={procuts} alt="Procuts logo"></img>
              <h3>ProCuts</h3>
            </div>
            <div className={styles.card}>
              <img src={redshoe} alt="RedShoe Sound Productions logo"></img>
              <h3>RedShoe Sound Productions</h3>
            </div>
            <div className={styles.card}>
              <img src={turbo} alt="Turbo Painting logo"></img>
              <h3>Turbo Painting</h3>
            </div>
            <div className={styles.card}>
              <img src={uglide} alt="Uglide Networks logo"></img>
              <h3>Uglide Networks</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
